<template>
  <div class="login">
    <div class="signup-body">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="content col-md-6 col-lg-5 p-0">
            <div class="header">
              <h3 class="text-center">Login</h3>
            </div>
            <hr>
            <div class="form pt-5 login-form">
              <el-form :label-position="labelPosition" label-width="90px" :model="login"  :rules="rules" ref="someForm">

                <el-form-item label="Email" prop="username">
                  <el-input type="email" v-model="login.username" id="login_username"></el-input>
                </el-form-item>

                <el-form-item label="Password" prop="password" style="position: relative">
                  <el-input type="password"
                            id="login_password"
                            v-model="login.password" @keyup.native.enter="auth(login)"></el-input>
                </el-form-item>

                <el-form-item class="keep-signed">
                  <el-checkbox label="Keep me signed in" name="type"></el-checkbox>
                </el-form-item>
                <el-alert
                  v-if="authRequest === 'error'"
                  :title="authError.title"
                  :description="authError.message"
                  type="error"
                  :closable="false"
                  :show-icon="true"
                  effect="dark">
                </el-alert>
                <div class="text-center buttons pb-4">
                  <el-button type="success"
                             @click='auth(login)'>
                    Login
                    <i class="el-icon-loading" v-show="authRequest == 'loading'"></i>
                  </el-button>
                </div>
              </el-form>
              <div class="text-center pb-3">
                <a href="/forgotpassword" class="forgot-link">Forgot password</a>
                <a href="/freelancersignup" class="forgot-link ml-3" @click.prevent="dialogVisible = true">
                  Signup instead
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <generic-sign-up-modal v-model="dialogVisible"/>
  </div>
</template>

<script>

  import store from '../vuex/store';
  import {mapGetters, mapActions} from 'vuex'
  import _ from 'lodash'
  import GenericSignUpModal from '~/components/GenericSignUpModal'

export default {
  store,
  data() {
    return {
      labelPosition: 'right',
      login: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          this.requiredRule('Email'),
          {
            type: 'email',
            trigger: 'blur',
            message: 'Email must be a valid email address'
          }
        ],
        password: [
          this.requiredRule('Password'),
        ],
      },
      dialogVisible: false,
    }
  },

  components: { GenericSignUpModal },

  computed: {
      ...mapGetters({
        authRequest: 'authStatus',
        authError: 'authError'
      }),
  },

  methods: {
    ...mapActions({
      auth: 'authentication',
    }),
    requiredRule (fieldName) {
      return {
        required: true,
        trigger: 'blur',
        message: `${_.startCase(fieldName)} is required`
      }
    },
    hideModal() {
      console.log(this.dialogVisible);
      this.dialogVisible = false
    },
  },
}
</script>

<style lang="scss">
.login-form {
  .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
    content: "";
    margin-right: 0px;
  }
}
.login-form label.el-form-item__label:after{
  content: "*";
  color: #f56c6c;
  margin-left: 4px;
}
.forgot-link {
  font-size: 12px;
  color: #E87722;
}
.forgot-link:hover{
  color: #E87722;
}
.keep-signed .el-form-item__content{
  line-height: 0px;
}
.login .signup-body{
  padding: 50px 0;
}
.login-form .buttons button:active, .login-form .buttons button:focus {
  outline: 0;
}
@media (max-width:767px) {
  .login .signup-body {
    padding: 20px !important;
  }
  .el-form--label-right .el-form-item__label {
    width: 100%;
    text-align: left;
    margin-bottom: 0;
  }
}
</style>
